import { Modal, Steps, Progress } from "antd";
import { IdentifierProps } from "types";
import { Link } from "react-router-dom";

export const TalentProgressJobs = ({ open, handleClose, data }: IdentifierProps) => {

  return (
    <Modal
      title="Incomplete Profile"
      open={open}
      footer={null}
      width="75%"
      centered
    >
      <p>Please complete your profile to access the job board.</p>
      <div className="pt-4">
        <div className="py-4">
          <Progress percent={data?.total} />
        </div>

        <Steps
          direction="vertical"
          current={ 
            data?.profile_section === 100 
            ? (data?.documents === 100 ? 2 : 1)
            : 0
          }
          className="text-primary"
          items={[
            {
              title: (
                <Link to="/settings/profile">
                  Complete your profile
                </Link>
              ),
              description: "",
              status: data?.profile_section === 100 ? 'finish' : 'wait'
            },
            // {
            //   title: (
            //     <Link to="/settings/documents">
            //       Upload your Documents
            //     </Link>
            //   ),
            //   description: "",
            //   status: data?.documents === 100 ? 'finish' : 'wait'
            // },
            // {
            //   title: (
            //     <Link to="/settings/personality-test">
            //       Take Personality Test
            //     </Link>
            //   ),
            //   description: "",
            //   status: data?.profile_section === 100 ? 'finish' : 'wait'
            // },
          ]}
        />
      </div>
    </Modal>
  );
};
