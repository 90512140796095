import { adminRoutePath } from 'config/routeMgt/adminRoutePath'
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import { useSignOut } from 'react-auth-kit';
import {Modal } from "antd";
import { NavLink } from 'react-router-dom'
import { useState } from "react";
import { hasPermission } from 'utils/hasPermission';

export const SuperAdmin = () => {
  const signOut = useSignOut();
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);
  const { userInfo } = useGetUserInfo();

  const showLogoutModal = () => {
    setLogoutModalVisible(true);
  };

  const handleLogout = () => {
    signOut();
    setLogoutModalVisible(false);
  };

  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  return (
    <div className="mt-5 flex flex-col gap-y-3 justify-center px-5">
      <NavLink to={adminRoutePath.home} className="sidebarLink">
        <i className="ri-bar-chart-line"></i>
        <span className="text-sm">Dashboard</span>
      </NavLink>
      {hasPermissionFor('View Admin users', 'Manage Admin users') &&
        <NavLink to={adminRoutePath.users} className="sidebarLink">
          <i className="ri-user-line text-xl"></i>
          <span className="text-sm ">Users</span>
        </NavLink>
      }
      {hasPermissionFor('View Jobs', 'Manage Jobs') &&
        <NavLink to={adminRoutePath.jobs} className="sidebarLink">
          <i className="ri-briefcase-line text-xl"></i>
          <span className="text-sm ">ALL Jobs</span>
        </NavLink>
      }
      {hasPermissionFor('View Applicants', 'Manage Applicants') &&
        <NavLink to={adminRoutePath.talents} className="sidebarLink">
          <i className="ri-flag-line text-xl"></i>
          <span className="text-sm">All Talents</span>
        </NavLink>
      }
      {hasPermissionFor('View Recruiters', 'Manage Recruiters') &&
        <NavLink to={adminRoutePath.recruiters} className="sidebarLink">
          <i className="ri-building-line text-xl"></i>
          <span className="text-sm">All Recruiters</span>
        </NavLink>
      }
      {hasPermissionFor('View Pricing', 'Manage Pricing') &&
        <NavLink to={adminRoutePath.finance} className="sidebarLink">
          <i className="ri-price-tag-2-line text-xl"></i>
          <span className="text-sm">Finances</span>
        </NavLink>
      }
      {/*<NavLink to={adminRoutePath.settings} className="sidebarLink">
      <i className="ri-settings-2-line text-xl"></i>
      <span className="text-sm">Settings</span>
    </NavLink>*/}
      <div className="sidebarLink cursor-pointer" onClick={showLogoutModal}>
        <i className="ri-logout-box-r-line text-xl"></i>
        <span className="text-sm">Logout</span>
      </div>

      <Modal
        title="Confirm Logout"
        visible={isLogoutModalVisible}
        onOk={handleLogout}
        onCancel={() => setLogoutModalVisible(false)}
        okText="Logout"
        cancelText="Cancel"
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  )
}