import { END_POINT, appName } from "config/environment";
import zoomIcon from "assets/images/zoom.webp";
import googleMeetIcon from "assets/images/googlemeet.webp";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { openNotification } from "utils/notification";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

export const MeetingSettings = () => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [zoomLoading, setZoomLoading] = useState(false);



  const redirectToGoogle = () => {
    setGoogleLoading(true);
    fetch(`${END_POINT.AUTH_URL}/oauth/google`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setGoogleLoading(false);
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong!',
          duration: 4.5,
        });
        throw new Error('Something went wrong!');
      })
      .then((data) => window.location.assign(data.url))
      .catch((error) => console.error(error));
  };


  const redirectToZoom = () => {
    setZoomLoading(true);
    fetch(`${END_POINT.AUTH_URL}/oauth/zoom`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setZoomLoading(false);
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong!',
          duration: 4.5,
        });
        throw new Error('Something went wrong!');
      })
      .then((data) => window.location.assign(data.url))
      .catch((error) => console.error(error));
  };


  return (
    <DashboardLayout>
      <Navbar title="Meeting Settings" description="" />
      <div className="flex justify-between mb-4">
        <div>
          <h1 className="font-semibold text-lg text-accent">Authorize Account Access </h1>
          <h3 className="text-sm">Connect your Google or Zoom account to schedule meetings effortlessly. Granting access allows {appName} to securely interact with your chosen service for seamless integration and scheduling.</h3>
        </div>
        <div className="flex items-center gap-x-3">

        </div>
      </div>
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2 py-2">
        <div>
          <h3 className="font-semibold text-md pb-2">Google Meet</h3>
          <p className="text-md">Click on the Google meet button to authorize</p>
        </div>
        <div className="col-span-3">
          <div className="flex gap-x-3">
            <button onClick={() => redirectToGoogle()} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-[200px] shadow-sm text-sm">
              {googleLoading ? <BeatLoader color="#222" /> : <img src={googleMeetIcon} alt="google auth" className="" />}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
        <div>
          <h3 className="font-semibold text-md pb-2">Zoom meeting</h3>
          <p className="text-md">Click on the Zoom button to authorize</p>
        </div>
        <div className="col-span-3">
          <div className="flex gap-x-3">
            <button onClick={() => redirectToZoom()} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-[200px] shadow-sm text-sm">
              {zoomLoading ? <BeatLoader color="#222" /> : <img src={zoomIcon} alt="zoom auth" className="" />}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
