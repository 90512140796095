import { Button } from './ui/button';


import { Share2, Trash } from 'lucide-react';

interface ProfileBulkActionsProps {
  selectedCount: number;
  onDeleteSelected: () => void;
  onShareSelected: () => void;
}

export function ProfileBulkActions({
  selectedCount,
  onDeleteSelected,
  onShareSelected,
}: ProfileBulkActionsProps) {

  if (selectedCount === 0) return null;

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm text-muted-foreground">
        {selectedCount} selected
      </span>



      <Button variant="outline" size="sm" onClick={onShareSelected}>
        <Share2 className="mr-2 size-4" />
        Share
      </Button>

      <Button
        variant="outline"
        size="sm"
        onClick={onDeleteSelected}
        className="text-destructive hover:text-destructive"
      >
        <Trash className="mr-2 size-4" />
        Delete
      </Button>

    </div>
  );
}