import { Input, Checkbox, Form, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { adminRoutePath } from "config/routeMgt/adminRoutePath";
import { Link } from "react-router-dom";
import {
  emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { loginUser } from "../hooks/useLoginUser";
import { useMutation } from "react-query";
import { useSignIn } from "react-auth-kit";
import { ISignInUserProps } from "../types";
import { openNotification } from "utils/notification";
import googleLogo from "assets/images/googleIcon.png";
import { useEffect, useState } from "react";
import { END_POINT } from "config/environment";
import { BeatLoader } from "react-spinners";

export const SignInForm = () => {
  const signIn = useSignIn();
  const { mutate, isLoading } = useMutation(loginUser);
  const [loginUrl, setLoginUrl] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = (data: any) => {
    const props: ISignInUserProps = {
      email: data.email,
      password: data.password,
    };

    mutate(props, {
      onError: (err: any) => {
        openNotification({
          title: "Error",
          state: "error",
          description: err.response.data.msg,
          duration: 8.0,
        });
      },
      onSuccess: (res: any) => {
        const result = res.data;
        if (
          signIn({
            token: result.token,
            tokenType: "Bearer",
            authState: result,
            expiresIn: 180,
          })
        ) {
          openNotification({
            title: "Success",
            state: "success",
            description: "Logged in successfully",
            duration: 4.5,
          });
        }
      },
    });
  };
  

  return (
    <>
      <Form onFinish={handleSubmit} layout="vertical" requiredMark={false}>
        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input placeholder="Enter your email" className="py-2 shadow-sm" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          // rules={textInputValidationRules}
          className="-mt-3"
        >
          <Input.Password
            placeholder="***********"
            className="shadow-sm py-2"
          />
        </Form.Item>
        <div className="flex justify-between mb-6 -mt-2">
          <Checkbox>Remember me</Checkbox>
          <Link
            to={adminRoutePath.forgot_password}
            className="text-primary font-medium"
          >
            Forgot password
          </Link>
        </div>
        <AppButton
          label="Sign in"
          type="submit"
          additionalClassNames={["button w-full py-1"]}
          isLoading={isLoading}
        />
      </Form>
    </>
  );
};
