import { useEffect, useState } from "react";
import { useGetTalentCount } from "../hooks/useGetTalentCount";
import { HomeCard } from "./HomeCard";
import { TalentProgress } from "./TalentProgress";

export const TalentCount = () => {
  const { data } = useGetTalentCount();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data?.profile.total !== 100 && !loading) {
        setIsOpen(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [data]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <HomeCard
          title="Accepted"
          count={data?.accepted_invites ? data?.accepted_invites : 0}
          percentage={40}
        />
        <HomeCard
          title="Pending"
          count={data?.pending_invites ? data?.pending_invites : 0}
          percentage={10}
        />
        <HomeCard
          title="Rejected"
          count={data?.rejected_invites ? data?.rejected_invites : 0}
          percentage={20}
        />

        {!loading && data?.profile.total !== 100 && (
          <TalentProgress
            open={isOpen}
            data={data?.profile}
            handleClose={() => setIsOpen(false)}
          />
        )}
      </div>



      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4 mt-12">

        <div className="bg-white rounded-lg shadow-md h-full p-6">
          <div className="pb-6">
            <div>
              <h3 className="text-base font-bold">Profile Metrics</h3>
            </div>
          </div>
          <div className="relative">
            <div>
              <ul className="p-2 mt-2 space-y-2 w-full">
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full w-[42px] h-[42px] bg-gray-100 relative text-center p-2">
                      <i className="ri-check-fill text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Profile Views</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Number of times the profile has been viewed by recruiters</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.profile_view_count}</div>
                    </div>
                  </div>
                </li>
                {/*<li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-eye-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Visibility Score</div>
                      <div className="text-gray-500 text-xs hidden sm:block">A composite score indicating how visible the profile is to recruiters</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">150</div>
                    </div>
                  </div>
                </li>*/}
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                      <i className="ri-chat-1-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Pre-chats Scheduled</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Number of pre-chats invited and attended</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.accepted_invites}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                      <i className="ri-briefcase-4-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Offers Received</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Number of job offers received</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.number_of_job_offers}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div className="bg-white rounded-lg shadow-md h-full p-6">
          <div className="pb-6">
            <div>
              <h3 className="text-base font-bold">Activity Metrics</h3>
            </div>
          </div>
          <div className="relative">
            <div>
              <ul className="p-2 mt-2 space-y-2 w-full">
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full w-[42px] h-[42px] bg-gray-100 relative text-center p-2">
                      <i className="ri-message-3-fill text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Message Response Rate</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Percentage of messages from recruiters responded to</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.message_response_rate}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                      <i className="ri-time-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Talent's Response Time</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Average time taken to respond to recruiter messages</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.message_response_time}</div>
                    </div>
                  </div>
                </li>
                {/*<li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-check-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Interview Success Rate</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Percentage of interviews that lead to job offers</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">5%</div>
                    </div>
                  </div>
                </li>*/}
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                      <i className="ri-search-2-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Search Visibility</div>
                      <div className="text-gray-500 text-xs hidden sm:block">How often the talent appears in recruiter searches.</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{data?.search_visibility}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
