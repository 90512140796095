import { Form, Input } from "antd";
import { AppButton } from "components/button/AppButton";
import googleLogo from "assets/images/googleIcon.png";
// import linkedinLogo from "assets/images/linkedinIcon.png";
import arrowBack from "../assets/images/left-arrow-svg.svg";
import {
  emailValidationRules,
  passwordValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { routePath } from "config/routeMgt/routePath";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createUser } from "../hooks/useCreateUser";
import { ICreateUserProps } from "../types";
import { openNotification } from "utils/notification";
import { useSignIn } from "react-auth-kit";
import { END_POINT } from "config/environment";
import { BeatLoader } from "react-spinners";

interface MyComponentProps {
  selectedOptionProp: string;
  setStepProp: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SignUpForm: React.FC<MyComponentProps> = ({
  selectedOptionProp,
  setStepProp,
}) => {
  const { mutate, isLoading } = useMutation(createUser);
  const [form] = Form.useForm();
  const signIn = useSignIn();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      role: selectedOptionProp,
    });
  }, [selectedOptionProp, form]);

  const is_corporate = selectedOptionProp === "recruiter" ? 0 : 1;
  const talent = selectedOptionProp === "talent" ? "talent" : "recruiter";

  const handleSubmit = (data: any) => {
    const props: ICreateUserProps = {
      email: data.email,
      password: data.password,
      type: talent,
      name: data.name,
      password_confirmation: data.password_confirmation,
      is_corporate,
    };

    mutate(props, {
      onError: (err: any) => {
        const error = err.response.data.data;
        openNotification({
          title: "Error",
          state: "error",
          description: `${error.email ? error.email : ""}
          `,
          duration: 8.0,
        });
      },
      onSuccess: (res: any) => {
        const result = res.data;
        if (
          signIn({
            token: result.token,
            tokenType: "Bearer",
            authState: result,
            expiresIn: 180,
          })
        ) {
          openNotification({
            title: "Success",
            state: "success",
            description: "Account created successfully",
            duration: 4.5,
          });
        }
      },
    });
  };


  const redirect = (platform:string) => {
    setLoading(true);
    fetch(`${END_POINT.AUTH_URL}/${platform === 'google' ? 'auth':'auth/linkedin'}?type=${talent}&is_corporate=${is_corporate}`, {
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        setLoading(false);
        throw new Error('Something went wrong!');
    })
    .then((data) => window.location.assign(data.url))
    .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="mb-6">
        <h2 className="text-2xl font-bold pb-1 text-primary">Sign up</h2>
      </div>
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item noStyle name="role">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Full Name"
          rules={textInputValidationRules}
          className=""
        >
          <Input
            placeholder="Enter your full name"
            className="shadow-sm py-2"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={emailValidationRules}
          className="-mt-3"
        >
          <Input placeholder="Enter your email" className="shadow-sm py-2" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={passwordValidationRules}
          className="-mt-3"
        >
          <Input.Password
            placeholder="***********"
            className="shadow-sm py-2"
          />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          label="Confirm Password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Field is required",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered does not match."
                );
              },
            }),
          ]}
          className="-mt-3"
        >
          <Input.Password
            placeholder="***********"
            className="shadow-sm py-2"
          />
        </Form.Item>
        <AppButton
          label="Sign up"
          type="submit"
          additionalClassNames={["button w-full py-1"]}
          isLoading={isLoading}
        />
      </Form>
      <button onClick={()=> redirect('google')} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-full mt-4 shadow-sm text-sm">
        <img src={googleLogo} alt="google auth" className="h-5" />
        <span>{loading ? <BeatLoader color="#222" /> :"Sign up with Google"}</span>
      </button>

      {/* <button onClick={()=>redirect('linkedin')} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-full mt-4 shadow-sm text-sm">
        <img src={linkedinLogo} alt="linkedin auth" className="h-5" />
        <span>{loading ? <BeatLoader color="#222" /> : "Sign up with Linkedin"}</span>
      </button> */}

      <div className="flex justify-center items-center gap-x-2 text-sm mt-5">
        <span className="text-accent">Already have an account?</span>
        <Link
          to={routePath.sign_in}
          className="text-primary font-medium hover:text-accent"
        >
          Sign in
        </Link>
      </div>

      <div className="mb-5 mt-8 flex justify-around items-center">
        <img
          src={arrowBack}
          alt="arrow left"
          className="h-6 cursor-pointer"
          onClick={() => setStepProp(false)}
        />
        <div className="flex items-center gap-x-1">
          <div className="h-2 w-2 rounded-full bg-gray-300"></div>
          <div style={{ height: 5 }} className="bg-primary w-8 rounded" />
        </div>
        <div></div>
      </div>
    </>
  );
};
