import { END_POINT } from "config/environment";
import type {
  Attachment,
  ChatRequestOptions,
  CreateMessage,
  Message,
} from 'ai';
import cx from 'classnames';
import type React from 'react';
import {
  useRef,
  useEffect,
  useState,
  useCallback,
  type Dispatch,
  type SetStateAction,
  type ChangeEvent,
  memo,
} from 'react';
import { toast } from 'sonner';
import { useLocalStorage, useWindowSize } from 'usehooks-ts';
import { useGetUserInfo } from "hooks/useGetUserInfo";

import { sanitizeUIMessages } from 'features/cvChat/lib/utils';

import {
  ArrowUpIcon,
  GoogleDriveIcon,
  PaperclipIcon,
  StopIcon,
} from './icons';
import { PreviewAttachment } from './preview-attachment';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import equal from 'fast-deep-equal';
import { UploadDropzone } from './upload-dropzone';
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function PureMultimodalInput({
  chatId,
  input,
  setInput,
  isLoading,
  stop,
  attachments,
  setAttachments,
  messages,
  setMessages,
  append,
  handleSubmit,
  className,
}: {
  chatId: string;
  input: string;
  setInput: (value: string) => void;
  isLoading: boolean;
  stop: () => void;
  attachments: Array<Attachment>;
  setAttachments: Dispatch<SetStateAction<Array<Attachment>>>;
  messages: Array<Message>;
  setMessages: Dispatch<SetStateAction<Array<Message>>>;
  append: (
    message: Message | CreateMessage,
    chatRequestOptions?: ChatRequestOptions,
  ) => Promise<string | null | undefined>;
  handleSubmit: (
    event?: {
      preventDefault?: () => void;
    },
    chatRequestOptions?: ChatRequestOptions,
  ) => void;
  className?: string;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { width } = useWindowSize();



  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight();
    }
  }, []);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
    }
  };

  const [localStorageInput, setLocalStorageInput] = useLocalStorage(
    'input',
    '',
  );

  useEffect(() => {
    if (textareaRef.current) {
      const domValue = textareaRef.current.value;
      // Prefer DOM value over localStorage to handle hydration
      const finalValue = domValue || localStorageInput || '';
      setInput(finalValue);
      adjustHeight();
    }
    // Only run once after hydration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalStorageInput(input);
  }, [input, setLocalStorageInput]);

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
    adjustHeight();
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadQueue, setUploadQueue] = useState<Array<string>>([]);
  const { token } = useGetUserInfo();

  const submitForm = useCallback(() => {

    // window.history.replaceState({}, '', `/chat/${chatId}`);

    handleSubmit(undefined, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      experimental_attachments: attachments,
    });


    setAttachments([]);

    setLocalStorageInput('');


    if (width && width > 768) {
      textareaRef.current?.focus();
    }
  }, [handleSubmit, token, attachments, setAttachments, setLocalStorageInput, width]);


  const uploadFile = useCallback(async (file: File) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('Chatid', chatId);

    try {
      const response = await fetch(`${END_POINT.BASE_URL}/v1/files-upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const { url, pathname, contentType } = data;

        return {
          url,
          name: pathname,
          contentType: contentType,
        };
      }
      const { error } = await response.json();
      toast.error(error);
    } catch (error) {
      toast.error('Failed to upload file, please try again!');
    }
  }, [chatId, token]);

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);

      setUploadQueue(files.map((file) => file.name));

      try {

        const uploadPromises = files.map(async (file) => {

          const uploadedFile = await uploadFile(file);
          if (uploadedFile) {
            return uploadedFile;
          }
          return undefined;
        });
        type UploadedFileAttachment = {
          url: string;
          name: string;
          contentType: string;
        };

        // Later in the component...
        const uploadedAttachments = await Promise.all(uploadPromises);
        const successfullyUploadedAttachments = uploadedAttachments.filter(
          (attachment): attachment is UploadedFileAttachment => !!attachment
        );


        setAttachments((currentAttachments) => [
          ...currentAttachments,
          ...successfullyUploadedAttachments,
        ]);

      } catch (error) {
        console.error('Error uploading files!', error);
      } finally {
        setUploadQueue([]);
      }
    },
    [setAttachments, uploadFile],
  );

  return (
    <div className="relative w-full flex flex-col gap-4">

      {messages.length === 0 &&
        attachments.length === 0 &&
        uploadQueue.length === 0 && (
          <UploadDropzone fileInputRef={fileInputRef} isLoading={isLoading} />
        )}



      <input
        type="file"
        className="fixed -top-4 -left-4 size-0.5 opacity-0 pointer-events-none"
        ref={fileInputRef}
        multiple
        accept="application/pdf"
        onChange={handleFileChange}
        tabIndex={-1}
      />

      {(attachments.length > 0 || uploadQueue.length > 0) && (
        <div className="flex flex-row gap-2 overflow-x-scroll items-end">
          {attachments.map((attachment) => (
            <PreviewAttachment
              key={attachment.url}
              attachment={attachment}
              onRemove={() => {
                setAttachments(
                  attachments.filter((a) => a.url !== attachment.url),
                );
              }}
            />
          ))}

          {uploadQueue.map((filename) => (
            <PreviewAttachment
              key={filename}
              attachment={{
                url: '',
                name: filename,
                contentType: '',
              }}
              isUploading={true}
            />
          ))}
        </div>
      )}

      <Textarea
        ref={textareaRef}
        placeholder="Send a message..."
        value={input}
        onChange={handleInput}
        className={cx(
          'min-h-[24px] max-h-[calc(75dvh)] overflow-hidden resize-none rounded-2xl !text-base bg-muted pb-10 dark:border-zinc-700',
          className,
        )}
        rows={2}
        autoFocus
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();

            if (isLoading) {
              toast.error('Please wait for the model to finish its response!');
            } else {
              submitForm();
            }
          }
        }}
      />

      <div className="absolute bottom-0 p-2 w-fit flex flex-row justify-start">
        <AttachmentsButton
          fileInputRef={fileInputRef}
          isLoading={isLoading || uploadQueue.length > 0}
        />
        <GoogleDriveButton isLoading={isLoading} />
      </div>

      <div className="absolute bottom-0 right-0 p-2 w-fit flex flex-row justify-end">
        {isLoading ? (
          <StopButton stop={stop} setMessages={setMessages} />
        ) : (
          <SendButton
            input={input}
            submitForm={submitForm}
            uploadQueue={uploadQueue}
          />
        )}
      </div>
    </div>
  );
}

export const MultimodalInput = memo(
  PureMultimodalInput,
  (prevProps, nextProps) => {
    if (prevProps.input !== nextProps.input) return false;
    if (prevProps.isLoading !== nextProps.isLoading) return false;
    if (!equal(prevProps.attachments, nextProps.attachments)) return false;

    return true;
  },
);

function PureAttachmentsButton({
  fileInputRef,
  isLoading,
}: {
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  isLoading: boolean;
}) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className="rounded-md rounded-bl-lg p-[7px] h-fit dark:border-zinc-700 hover:dark:bg-zinc-900 hover:bg-zinc-200"
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
          disabled={isLoading}
          variant="ghost"
        >
          <PaperclipIcon size={14} />
        </Button>
      </TooltipTrigger>
      <TooltipContent align="start">Attachments</TooltipContent>
    </Tooltip>
  );
}

const AttachmentsButton = memo(PureAttachmentsButton);


function PureGoogleDriveButton({ isLoading }: { isLoading: boolean }) {
  const { token } = useGetUserInfo();
  const location = useLocation();
  const navigate = useNavigate();
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [driveFiles, setDriveFiles] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);


  useEffect(() => {
    if (location.state?.showDriveModal) {
      setShowDriveModal(true);
      setAccessToken(location.state.driveAccessToken);
      
      
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.pathname, location.state, navigate]);

  useEffect(() => {
    const loadDriveFiles = async () => {
      
      if (!accessToken) return;

      try {
        const response = await axios.get(
          'https://www.googleapis.com/drive/v3/files',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              pageSize: 100,
              fields: 'files(id,name,mimeType,webContentLink)',
            },
          }
        );
        
        setDriveFiles(response.data.files);
      } catch (error) {
        toast.error('Failed to load files from Google Drive');
      }
    };

    if (showDriveModal) {
      loadDriveFiles();
    }
  }, [showDriveModal, accessToken]);

  const handleGoogleDriveAuth = async () => {
    try {
      const driveState = 'drive_auth';
      const response = await fetch(
        `${END_POINT.BASE_URL}/v1/google-drive/google?state=${driveState}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      window.location.assign(data.url);
    } catch (error) {
      console.error('Google Drive authentication failed:', error);
    }
  };

  const handleDriveFileSelect = async (file: any) => {
    if (!accessToken) {
      console.log('Access token not available');
      return;
    }

    try {
      const downloadResponse = await axios.get(file.webContentLink, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const uploadedFile = new File([downloadResponse.data], file.name, {
        type: file.mimeType,
      });

      console.log('File downloaded:', uploadedFile);
    } catch (error) {
      toast.error('Failed to download file from Google Drive');
    }
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className="rounded-md rounded-bl-lg p-[7px] h-fit dark:border-zinc-700 hover:dark:bg-zinc-900 hover:bg-zinc-200"
            onClick={(event) => {
              event.preventDefault();
              handleGoogleDriveAuth();
            }}
            disabled={isLoading}
            variant="ghost"
          >
            <GoogleDriveIcon size={14} />
          </Button>
        </TooltipTrigger>
        <TooltipContent align="start">Google Drive</TooltipContent>
      </Tooltip>

      {showDriveModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
            <div className="flex justify-between mb-4">
              <h3 className="text-lg font-semibold">Select from Google Drive</h3>
              <button onClick={() => setShowDriveModal(false)}>&times;</button>
            </div>
            <div className="max-h-96 overflow-y-auto">
              {driveFiles.length === 0 ? (
                <div className="p-4 text-center text-gray-500">
                  No files found in Google Drive
                </div>
              ) : (
                driveFiles.map((file) => (
                  <div
                    key={file.id} // Use unique ID instead of name
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      handleDriveFileSelect(file);
                      setShowDriveModal(false);
                    }}
                  >
                    <span className="font-medium">{file.name}</span>
                    <span className="text-xs text-gray-500 ml-2">
                      ({file.mimeType})
                    </span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const GoogleDriveButton = memo(PureGoogleDriveButton);



function PureStopButton({
  stop,
  setMessages,
}: {
  stop: () => void;
  setMessages: Dispatch<SetStateAction<Array<Message>>>;
}) {
  return (
    <Button
      className="rounded-full p-1.5 h-fit border dark:border-zinc-600"
      onClick={(event) => {
        event.preventDefault();
        stop();
        setMessages((messages) => sanitizeUIMessages(messages));
      }}
    >
      <StopIcon size={14} />
    </Button>
  );
}

const StopButton = memo(PureStopButton);

function PureSendButton({
  submitForm,
  input,
  uploadQueue,
}: {
  submitForm: () => void;
  input: string;
  uploadQueue: Array<string>;
}) {
  return (
    <Button
      className="rounded-full p-1.5 h-fit border dark:border-zinc-600"
      onClick={(event) => {
        event.preventDefault();
        submitForm();
      }}
      disabled={input.length === 0 || uploadQueue.length > 0}
    >
      <ArrowUpIcon size={14} />
    </Button>
  );
}

const SendButton = memo(PureSendButton, (prevProps, nextProps) => {
  if (prevProps.uploadQueue.length !== nextProps.uploadQueue.length)
    return false;
  if (prevProps.input !== nextProps.input) return false;
  return true;
});