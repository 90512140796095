
import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { SidebarToggle } from 'features/cvChat/components/sidebar-toggle';
import { Button } from 'features/cvChat/components/ui/button';
import { memo } from 'react';
import { type VisibilityType } from './visibility-selector';
import { routePath } from 'config/routeMgt/routePath';

function PureChatHeader({
  chatId,
  selectedModelId,
  selectedVisibilityType,
  isReadonly,
  isChatPage,
}: {
  chatId: string;
  selectedModelId: string;
  selectedVisibilityType: VisibilityType;
  isReadonly: boolean;
  isChatPage: boolean;
}) {

  const { width: windowWidth } = useWindowSize();

  return (

    <header className="flex sticky top-0 bg-background py-1.5 items-center px-2 md:px-2 gap-2">
      {(windowWidth > 768) && (
        <SidebarToggle />
      )}


      {!isReadonly && (
        <Button
          variant="default"
          type="button"
          className="order-1 md:order-4"
          asChild
        >
          <Link to={isChatPage ? routePath.chatProfiles(chatId).path : routePath.chatDetails(chatId).path}><span>{isChatPage ? 'Uploaded Profiles' : 'Back to Chat'}</span></Link>

        </Button>
      )}
    </header>
  );
}

export const ChatHeader = memo(PureChatHeader, (prevProps, nextProps) => {
  return prevProps.selectedModelId === nextProps.selectedModelId;
});
