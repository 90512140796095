

import { motion } from 'framer-motion';
import { Button } from './ui/button';
import type { ChatRequestOptions, CreateMessage, Message } from 'ai';
import { memo } from 'react';
import { ArrowUpIcon } from './icons';


function PureUploadDropzone({
  fileInputRef,
  isLoading,
}: {
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  isLoading: boolean;
}) {
  const suggestedActions = [
    {
      title: 'Upload CVs here',
      label: 'Supports text documents (like .pdf, .docx, .pptx, .html, .csv, .json) containing up to 800,000 characters.',
      action: 'Upload CVs here',
    }
  ];

  return (
    <div className="grid sm:grid-cols-1 gap-6 w-full">
      {suggestedActions.map((suggestedAction, index) => (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ delay: 0.05 * index }}
          key={`suggested-action-${suggestedAction.title}-${index}`}
          className={index > 1 ? 'hidden sm:block' : 'block'}
        >
          <Button
            variant="ghost"
            className="text-left border rounded-xl px-6 py-4 text-sm flex-1 gap-2 sm:flex-col w-full h-auto justify-center items-center hover:bg-muted/50 transition-colors"
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current?.click();
            }}
            disabled={isLoading}
          >
            <ArrowUpIcon size={14} />
            <span className="font-medium text-base">{suggestedAction.title}</span>
            <span className="text-muted-foreground whitespace-normal break-words text-sm">
              {suggestedAction.label}
            </span>
          </Button>
        </motion.div>
      ))}
    </div>
  );
}

export const UploadDropzone = memo(PureUploadDropzone);
