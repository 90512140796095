import React, { useState, useEffect } from 'react';
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import { useParams } from 'react-router-dom';
import { getProfilesByChatId } from 'features/cvChat/lib/db/queries';
import { ProfileList } from 'features/cvChat/components/profile-list';
import { ProfileDetails } from 'features/cvChat/components/profile-details';
import { Profile } from 'features/cvChat/lib/db/schema';
import "../../../../assets/style.css";
import { DashboardLayout } from 'components/layout/components/DashboardLayout';

export const ProfilesPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { userInfo, token } = useGetUserInfo();
  
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    
    const fetchProfiles = async () => {
      if (userInfo?.id) {
      
        const fetchedProfiles = await getProfilesByChatId({ chatId: id, token:token });       
        setProfiles(fetchedProfiles);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [id, userInfo?.id, token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userInfo || !userInfo.id) {
    return null;
  }

  return (
    <>
    <DashboardLayout>
    <div className="flex h-[calc(100vh-3.5rem)] overflow-hidden">
      {/* Profile List Sidebar */}
      <div className="w-80 border-r border-border hidden md:block overflow-y-auto">
        <ProfileList profiles={profiles} setProfiles={setProfiles}/>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-y-auto">
        <div className="max-w-2xl mx-auto p-4">
          <ProfileDetails />
        </div>
      </div>
    </div>
    </DashboardLayout>
    </>
  );
};
