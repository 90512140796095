/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { END_POINT } from "config/environment";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { ISaveChatProp, ChatUserIDProp, DocumentProps } from "../types/chatprops";
import { AssistantContent, ToolContent, UserContent } from "ai";




export const saveChat = async (props: ISaveChatProp) => {
  const url = `${END_POINT.BASE_URL}/v1/savechat`;
  const { token } = useGetUserInfo();
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    id: props.id,
    recruiter_id: props.recruiter_id,

    title: props.title,
  };

  const response = await axios.post(url, data, config);
  return response;
};


export async function createTag({
  chatId,
  name,
}: {
  chatId: string;
  name: string;
}) {
  const url = `${END_POINT.BASE_URL}/v1/create-tag`;
  const { token } = useGetUserInfo();
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {

    const data: any = {
      chatId: chatId,
      name: name,
    };

    const response = await axios.post(url, data, config);
    return response;

  } catch (error) {
    console.error('Failed to create tag in database');
    throw error;
  }
}


export async function getVotesByChatId({ id }: { id: string }) {
  const url = `${END_POINT.BASE_URL}/v1/getvotes-by-chatid?id=${id}`;
  const { token } = useGetUserInfo();

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error('Failed to get votes by chat id from database', error);
    throw error;
  }
}


type Suggestion = {
  documentId: string;
  originalText: string;
  documentCreatedAt: Date;
  suggestedText: string;
  description: string;
  isResolved: boolean;
  userId: boolean;

};


export async function saveSuggestions({
  suggestions,
}: {
  suggestions: Array<Suggestion>;
}) {



  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/save-suggestion`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {

    const response = await axios.post(url, suggestions, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save suggestions in database');
    throw error;
  }
}


export async function deleteChatById({ id }: { id: string }) {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/delete-chat`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to delete chat by id from database');
    throw error;
  }
}


export const getChatsByUserId = async (props: ChatUserIDProp) => {
  const url = `${END_POINT.BASE_URL}/v1/get-chat-userid`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    recruiter_id: props.recruiter_id,
  };

  const response = await axios.post(url, data, config);
  return response;
};



export const getChatById = async (props: { id: any, token: string }) => {
    
  const url = `${END_POINT.BASE_URL}/v1/get-chat-id?id=${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  try {
    const response = await axios.get(url, config);  // Change to GET request
    return response.data; // Assuming response contains chat data
  } catch (error) {
    console.error('Failed to get chat by id from API', error);
    throw error;
  }
};

export const getMessageById = async (props: { id: string }) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/get-message-id?id=${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);  // Change to GET request
    return response.data; // Assuming response contains chat data
  } catch (error) {
    console.error('Failed to get chat by id from API', error);
    throw error;
  }
};

export const updateChatVisiblityById = async (props: { chatId: string, visibility: 'private' | 'public' }) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/update-chat-visibility-id?id=${props.chatId}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data: any = {
    id: props.chatId,
    visibility: props.visibility,
  };

  try {
    const response = await axios.post(url, data, config);  // Change to GET request
    return response.data; // Assuming response contains chat data
  } catch (error) {
    console.error('Failed to get chat by id from API', error);
    throw error;
  }
};


interface Message {
  id: string;
  chatId: string;
  role: 'assistant' | 'tool' | 'user' | 'system';
  content: AssistantContent | ToolContent | UserContent;
  createdAt: Date;
}

export async function saveMessages({ messages }: { messages: Array<Message> }) {
  const url = `${END_POINT.BASE_URL}/v1/save-messages`;
  const { token } = useGetUserInfo();
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(url, { messages }, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save messages via API', error);
    throw error;
  }
}


export const getMessagesByChatId = async (props: { id: any, token: string }) => {

  const url = `${END_POINT.BASE_URL}/v1/messages-by-chat-id?id=${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  try {
    const response = await axios.get(url, config);  // Changed to GET request
    return response.data;  // Assuming response contains messages data
  } catch (error) {
    console.error('Failed to get messages by chat id from API', error);
    throw error;
  }
};

export const deleteMessagesByChatIdAfterTimestamp = async (props: { chatId: string, timestamp: Date }) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/delete-messages-timestamp?id=${props.chatId}?timestamp=${props.timestamp}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get messages by chat id and timestamp from API', error);
    throw error;
  }
};

export async function deleteDocumentsByIdAfterTimestamp({
  id,
  timestamp,
}: {
  id: number;
  timestamp: Date;
}) {
  const { token } = useGetUserInfo();

  const url = `${END_POINT.BASE_URL}/v1/delete-documents-timestamp?id=${id}?timestamp=${timestamp}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(url, config);
    return response.data;
  } catch (error) {
    console.error(
      'Failed to delete documents by id after timestamp from database',
    );
    throw error;
  }
}


export const saveDocument = async (props: DocumentProps) => {
  const url = `${END_POINT.BASE_URL}/v1/save-document`; // Update the URL to the appropriate endpoint
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const data = {
    id: props.id,
    title: props.title,
    kind: props.kind,
    content: props.content,
    userId: props.recruiter_id,
  };

  try {

    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save document via API', error);
    throw error;
  }
};




export const getDocumentById = async (props: { id: number }) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/get-document`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };


  try {

    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save document via API', error);
    throw error;
  }
};





export const getDocumentsById = async (props: { id: number }) => {

  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/get-documents`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };


  try {

    const response = await axios.post(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save document via API', error);
    throw error;
  }
};


export const saveProfile = async (props: {
  chatId: string;
  name: string;
  email: string;
  phone: string;
  summary: string;
  skills: any;
  experience: any;
  education: any;
  achievements?: any;
  score: number;
  linkedinUrl?: string;
  linkedinImage?: string;
  githubUrl?: string;
  githubImage?: string;
  bestSuitedFor?: string;
  token: string;
}) => {
  const url = `${END_POINT.BASE_URL}/v1/save-cv-profile`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const data = {
    chatId: props.chatId,
    name: props.name,
    email: props.email,
    phone: props.phone,
    summary: props.summary,
    skills: props.skills,
    experience: props.experience,
    education: props.education,
    achievements: props.achievements,
    score: props.score,
    linkedinUrl: props.linkedinUrl,
    linkedinImage: props.linkedinImage,
    githubUrl: props.githubUrl,
    githubImage: props.githubImage,
    bestSuitedFor: props.bestSuitedFor,
  };

  try {
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Failed to save profile via API', error);
    throw error;
  }
};


export const getProfilesByChatId = async (props: {
  chatId: string;
  token: string;
}) => {

  const url = `${END_POINT.BASE_URL}/v1/profiles-by-chatid/${props.chatId}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get profiles by chat id via API', error);
    throw error;
  }
};





export const getProfileById = async (props: { id: string, token: string }) => {
  const url = `${END_POINT.BASE_URL}/v1/profile/${props.id}`;
  
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get profile by id via API', error);
    throw error;
  }
};


export const getProfileByUserId = async (props: { userId: string; }) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/profile-by-userid/${props.userId}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get profile by user id via API', error);
    throw error;
  }
};

export const deleteProfileById = async (props: { id: string }) => {
  const url = `${END_POINT.BASE_URL}/v1/delete-profile/${props.id}`;
  const { token } = useGetUserInfo();
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to delete profile via API', error);
    throw error;
  }
};


export const voteMessage = async ({
  chatId,
  messageId,
  type,
}: {
  chatId: string;
  messageId: string;
  type: 'up' | 'down';
}) => {
  const url = `${END_POINT.BASE_URL}/v1/vote-message`; // Adjust this endpoint URL as needed
  const { token } = useGetUserInfo(); // Assuming you get the token from a hook
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    chatId,
    messageId,
    isUpvoted: type === 'up',
  };

  try {

    const checkUrl = `${END_POINT.BASE_URL}/v1/votes?messageId=${messageId}&chatId=${chatId}`;
    const checkResponse = await axios.get(checkUrl, config);

    if (checkResponse.data.exists) {

      const updateUrl = `${END_POINT.BASE_URL}/v1/vote-message`; // Use the appropriate endpoint to update the vote
      const updateResponse = await axios.put(updateUrl, data, config);
      return updateResponse.data;
    } else {

      const createResponse = await axios.post(url, data, config);
      return createResponse.data;
    }
  } catch (error) {
    console.error('Failed to vote message via API', error);
    throw error;
  }
};

export const getTagsByChatId = async (props: {
  chatId: string;
}) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/tags-by-chatid/${props.chatId}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get tags by chat id via API', error);
    throw error;
  }
};

export const tagProfiles = async (props: {
  profileIds: string[];
  tagId: string;
}) => {

  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/tag-profiles`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    profileIds: props.profileIds,
    tagId: props.tagId,
  };

  try {
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Failed to tag profiles via API', error);
    throw error;
  }
};

export const getProfileTags = async (props: {
  profileId: string;
}) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/profile-tags/${props.profileId}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Failed to get profile tags via API', error);
    throw error;
  }
};

export async function getSuggestionsByDocumentId({
  documentId,
}: {
  documentId: string;
}) {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/save-suggestions-by-documentsid/${documentId}`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error(
      'Failed to get suggestions by document version from database',
    );
    throw error;
  }
}

export const removeProfileTag = async (props: {
  profileId: string;
  tagId: string;
}) => {
  const { token } = useGetUserInfo();
  const url = `${END_POINT.BASE_URL}/v1/remove-profile-tag`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    profileId: props.profileId,
    tagId: props.tagId,
  };

  try {
    const response = await axios.delete(url, { data, ...config });
    return response.data;
  } catch (error) {
    console.error('Failed to remove profile tag via API', error);
    throw error;
  }
};
