import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserInfo } from "hooks/useGetUserInfo";

import { Chat } from 'features/cvChat/components/chat';
import { DEFAULT_MODEL_NAME, models } from 'features/cvChat/lib/ai/models';
import { getChatById, getMessagesByChatId } from 'features/cvChat/lib/db/queries';
import { convertToUIMessages } from 'features/cvChat/lib/utils';
import { DataStreamHandler } from 'features/cvChat/components/data-stream-handler';
import { DashboardLayout } from 'components/layout/components/DashboardLayout';
import { SidebarProvider } from 'features/cvChat/components/ui/sidebar';
import { AppSidebar } from 'features/cvChat/components/app-sidebar';

export default function ChatPage() {
  const { id } = useParams<{ id: string }>();
  const { token } = useGetUserInfo();

  const { userInfo } = useGetUserInfo();
  const navigate = useNavigate();
  const [cookies] = useCookies(['model-id']);
  const [chat, setChat] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    const fetchChatData = async () => {

      const chatData = await getChatById({ id: id, token: token });
      // console.log(token);
      
      // if (!chatData) {
      //   <NotFoundPage />
      //   return;
      // }

      // if (chatData.visibility === 'private') {
      //   if (!userInfo || userInfo.id !== chatData.userId) {
      //     navigate('/404');
      //     return;
      //   }
      // }

      setChat(chatData);
      const messagesFromDb = await getMessagesByChatId({ id, token });
      setMessages(convertToUIMessages(messagesFromDb));
    };

    fetchChatData();
  }, [id, userInfo, navigate, token]);

  if (!chat) return <div>Loading...</div>;

  const modelIdFromCookie = cookies['model-id'];
  const selectedModelId = models.find((model) => model.id === modelIdFromCookie)?.id || DEFAULT_MODEL_NAME;
  const isCollapsed = 'true';
  // const { userInfo } = useGetUserInfo();
  const session = userInfo;

  return (
    <>
      <DashboardLayout>
        <SidebarProvider defaultOpen={!isCollapsed}>
          <AppSidebar user={session} />
          <Chat
            id={chat.id}
            initialMessages={messages}
            selectedModelId={selectedModelId}
            selectedVisibilityType={chat.visibility}
            isReadonly={userInfo?.id !== chat.userId}
            isChatPage={true}
          />
          <DataStreamHandler id={id} />
        </SidebarProvider>
      </DashboardLayout>
    </>
  );
}
