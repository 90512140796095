export const routePath = {
  home: `/`,
  sign_in: `/sign-in`,
  sign_up: `/sign-up`,
  google: `/auth/google`,
  googleOauth: `/oauth/google`,
  linkedin: `/auth/linkedin`,
  linkedinOauth: `/oauth/linkedin`,
  zoomOauth: `/oauth/zoom`,
  forgot_password: `/forgot-password`,
  reset_password: `/password-reset`,
  jobs: `/jobs`,
  jobDetails: (id?: number | string) => ({
    format: `/jobs/:id`,
    path: `/jobs/${id}`,
  }),
  talents: `/talents`,
  talentDetails: (id?: number | string) => ({
    format: `/talents/:id`,
    path: `/talents/${id}`,
  }),
  folders: `/folders`,
  folderDetails: (id?: number | string) => ({
    format: `/folders/:id`,
    path: `/folders/${id}`,
  }),

  // chats: `/cv-chat/chats`,
  chatDetails: (id?: number | string) => ({
    format: `/cv-chat/chats/:id`,
    path: `/cv-chat/chats/${id}`,
  }),

  chatProfiles: (id?: number | string) => ({
    format: `/cv-chat/chats/:id/profiles`,
    path: `/cv-chat/chats/${id}/profiles`,
  }),

  chatProfilesById: (id?: number | string, profileId?: number | string) => ({
    format: `/cv-chat/chats/:id/profiles?profileId=:profileId`,
    path: `/cv-chat/chats/${id}/profiles?profileId=${profileId}`,
  }),

  pricing: `/pricing`,
  verifyPayment: `/payment/verify`,
  teamInvite: `/team-invitation`,
  message: `/message:id`,
  messages: `/message`,
  settings: `/settings`,
  profile: `/settings/profile`,
  documents: `/settings/documents`,
  password: `/settings/password`,
  billings: `/settings/billings`,
  personalityTest: `/settings/personality-test`,
  meetingSettings: `/meeting-settings`,
  postingSettings: `/posting-settings`,
  cvchat: '/cv-chat',
  invites: `/invites`,
  team: `/team`,

  terms: '/legal/terms-and-conditions',
  privacy: '/legal/privacy-policy',
};

