

import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from 'features/cvChat/components/icons';
import { SidebarHistory } from 'features/cvChat/components/sidebar-history';
import { SidebarUserNav } from 'features/cvChat/components/sidebar-user-nav';
import { Button } from 'features/cvChat/components/ui/button';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  useSidebar,
} from 'features/cvChat/components/ui/sidebar';

import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';
import { routePath } from "config/routeMgt/routePath";

export interface User {
  id?: string
  name?: string | null
  email?: string | null
  image?: string | null
}

export function AppSidebar({ user }: { user: User | undefined }) {
  const { userInfo } = useGetUserInfo();

  const router = useNavigate();
  const { setOpenMobile } = useSidebar();

  return (
    <Sidebar className="group-data-[side=left]:border-r-0">
      <SidebarHeader>
        <SidebarMenu>
          <div className="flex flex-row justify-between items-center">
            <a
              href="/"
              onClick={() => {
                setOpenMobile(false);
              }}
              className="flex flex-row gap-3 items-center"
            >
              <span className="text-lg font-semibold px-2 hover:bg-muted rounded-md cursor-pointer">
                CV Chatbot
              </span>
            </a>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  type="button"
                  className="p-2 h-fit"
                  onClick={() => {
                    setOpenMobile(false);
                    router(routePath.cvchat);
                  }}
                >
                  <PlusIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent align="end">New Chats</TooltipContent>
            </Tooltip>
          </div>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarHistory user={userInfo} />
      </SidebarContent>
      <SidebarFooter>{userInfo && <SidebarUserNav user={userInfo} />}</SidebarFooter>
    </Sidebar>
  );
}

