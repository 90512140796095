import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IReportProps } from "../types";



export const reportTalent = async (props: IReportProps) => {
    const url = `${END_POINT.BASE_URL}/v1/report-user`;
    const config = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${props.token}`,
        },
    };
    // necessary to make immediate changes when in  a central place when schema changes
    const data: any = {
        reportee_id: props.reportee_id,        
        reason_id: props.reason_id,
        comment: props.comment,
        id: props?.id,
    };

    const response = await axios.post(url, data, config);
    return response;
};

export const useReportTalent = () => {
    return useMutation(reportTalent);
};
