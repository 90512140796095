import { Form, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import { IdentifierProps } from "types";
import { IReportProps } from "../types";
import {
  generalValidationRules,
  textInputValidationRulesOpt,
} from "utils/formHelpers/validations";
import { useReportTalent } from "../hooks/useReportTalent";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { openNotification } from "utils/notification";
import { useParams } from "react-router-dom";

export const ReportTalent = ({ open, handleClose }: IdentifierProps) => {

  const { userInfo, token } = useGetUserInfo();
  const { mutate, isLoading } = useReportTalent();
  const [form] = Form.useForm();
  const params = useParams();
  const id = params.id;


  const handleSubmit = (data: any) => {


    const props: IReportProps = {
      reportee_id: id,
      token,
      reason_id: data.reasons,
      comment: data.note,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data && err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Talent Reported Successful",
          duration: 4.5,
        });
        form.resetFields();
        handleClose();
      },
    });
  };
  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Report Talent"
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        className="mt-5"
        form={form}
      >
        <Form.Item
          name="reasons"
          label="Reason for report"
          rules={generalValidationRules}
        >
          <Select
            allowClear
            placeholder="Select"
            options={[
              { value: 1, label: "Harassment or bullying" },
              { value: 2, label: "Impersonation or fake accounts" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="note"
          label="Explanation"
          rules={textInputValidationRulesOpt}
        >
          <TextArea rows={3} />
        </Form.Item>
        <AppButton type="submit" label="Save" isLoading={isLoading} />
      </Form>


    </Modal>
  );
};
