import { END_POINT, appName } from "config/environment";
import linkedinIcon from "assets/images/linkedin.png";
import indeedIcon from "assets/images/indeed.png";
import glassdoorIcon from "assets/images/glassdoor.png";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { openNotification } from "utils/notification";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

// Define loading state with an index signature to allow dynamic keys
interface LoadingState {
    [key: string]: boolean;
}

export const JobBoardSettings = () => {
    const [loading, setLoading] = useState<LoadingState>({
        linkedin: false,
        indeed: false,
        glassdoor: false,
    });

    const redirectToPlatform = (platform: string) => {
        setLoading((prev) => ({ ...prev, [platform]: true }));
        fetch(`${END_POINT.AUTH_URL}/jboauth/${platform}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                setLoading((prev) => ({ ...prev, [platform]: false }));
                openNotification({
                    title: "Error",
                    state: "error",
                    description: "Something went wrong!",
                    duration: 4.5,
                });
                throw new Error("Something went wrong!");
            })
            .then((data) => window.location.assign(data.url))
            .catch((error) => console.error(error));
    };

    return (
        <DashboardLayout>
            <Navbar title="Job Board Settings" description="" />
            <div className="container mx-auto px-4 py-6">
                <div className="text-center mb-6">
                    <h1 className="text-xl font-semibold text-accent">Authorize Job Board Access</h1>
                    <p className="mt-2 text-sm text-gray-600">
                        Connect your LinkedIn, Indeed, or Glassdoor account to post jobs seamlessly from {appName}.
                    </p>
                </div>
                <div className="space-y-6">
                    {[{ name: "LinkedIn", key: "linkedin", icon: linkedinIcon },
                    { name: "Indeed", key: "indeed", icon: indeedIcon },
                    { name: "Glassdoor", key: "glassdoor", icon: glassdoorIcon }].map(({ name, key, icon }) => (
                        <div key={key} className="flex items-center justify-between p-4 border rounded-md shadow-sm hover:shadow-lg transition duration-300 ease-in-out">
                            <div className="flex items-center space-x-4">
                                {/* <img src={icon} alt={`${name} logo`} className="w-8 h-8" /> */}
                                <div>
                                    <h3 className="text-md font-semibold">{name}</h3>
                                    <p className="text-sm text-gray-600">Click on the {name} button to authorize</p>
                                </div>
                            </div>
                            <button
                                onClick={() => redirectToPlatform(key)}
                                className="flex items-center justify-center px-4 py-2 border border-slate-300 rounded bg-white text-accent hover:bg-accent hover:text-white transition duration-200"
                            >
                                {loading[key] ? <BeatLoader color="#222" size={10} /> : "Authorize"}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </DashboardLayout>
    );
};
