import type { Profile } from 'features/cvChat/lib/db/schema';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import from react-router-dom
import { Avatar, AvatarImage, AvatarFallback } from 'features/cvChat/components/ui/avatar';
import { GithubIcon, LinkedInIcon } from './icons';
import { getProfileById } from '../lib/db/queries';
import { useGetUserInfo } from 'hooks/useGetUserInfo';

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};


export const ProfileDetails = () => {

  // const params = useParams();
  const location = useLocation();

  const { token } = useGetUserInfo();
  // const id = params.id as string;
  const queryParams = new URLSearchParams(location.search);
  const profileId = queryParams.get('profileId') as string;

  const [selectedProfile, setProfiles] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);

  // Fetch profiles when the component mounts
  useEffect(() => {
    const loadProfiles = async () => {
      try {

        const fetchedProfiles = await getProfileById({ id: profileId, token: token });

        setProfiles({
          ...fetchedProfiles,
          skills: JSON.parse(fetchedProfiles.skills || "[]"),
          experience: JSON.parse(fetchedProfiles.experience || "[]"),
          education: JSON.parse(fetchedProfiles.education || "[]"),
          achievements: JSON.parse(fetchedProfiles.achievements || "[]"),
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching profiles:", error);
        setLoading(false);
      }
    };

    loadProfiles();
  }, [profileId, token]);





  if (loading) {
    return (
      <div className="flex items-center justify-center h-full text-muted-foreground">
        Loading...
      </div>
    );
  }

  if (!selectedProfile) {
    return (
      <div className="flex items-center justify-center h-full text-muted-foreground">
        Select a profile to view details
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">

        <Avatar className="size-20">
          {selectedProfile.linkedinImage ? (
            <AvatarImage
              src={selectedProfile.linkedinImage}
              alt={selectedProfile.name}
            />
          ) : (
            <AvatarFallback className="bg-primary/10">
              {getInitials(selectedProfile.name)}
            </AvatarFallback>
          )}
        </Avatar>
        <div>
          <h1 className="text-2xl font-bold">{selectedProfile.name}</h1>
          <div className="flex items-center gap-2">
            {selectedProfile.linkedinUrl && (
              <a
                href={selectedProfile.linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted-foreground hover:text-foreground transition-colors"
              >
                <LinkedInIcon size={24} />
              </a>
            )}
            {selectedProfile.githubUrl && (
              <a
                href={selectedProfile.githubUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted-foreground hover:text-foreground transition-colors"
              >
                <GithubIcon size={24} />
              </a>
            )}
          </div>

          <p className="text-muted-foreground">
            Rating: {selectedProfile.score}/100
          </p>
        </div>
      </div>

      <section id="contact-info" className="flex flex-col gap-2">
        <h2 className="text-xl font-semibold mb-2">Contact Info</h2>
        <a
          href={`mailto:${selectedProfile.email}`}
          className="text-muted-foreground hover:text-foreground transition-colors"
        >
          {selectedProfile.email}
        </a>
        <p className="text-muted-foreground">{selectedProfile.phone}</p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <p className="text-muted-foreground">{selectedProfile.summary}</p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">Skills</h2>
        <div className="flex flex-wrap gap-2">
          {selectedProfile.skills.map((skill: string) => (
            <span
              key={skill}
              className="px-2 py-1 bg-accent rounded-full text-sm skill"
            >
              {skill}
            </span>
          ))}
        </div>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">Experience</h2>
        <div className="space-y-4">
          {selectedProfile.experience.map((exp: any, index: number) => (
            <div
              key={`${exp.company}-${exp.title}-${index}`}
              className="border-l-2 pl-4"
            >
              <h3 className="font-medium">{exp.title}</h3>
              <p className="text-sm text-muted-foreground">{exp.company}</p>
              <p className="text-sm text-muted-foreground">
                {exp.startDate} - {exp.endDate}
              </p>
              <ul className="mt-2 list-disc list-inside space-y-1">
                {exp.description.split('- ').filter(Boolean).map((point: string, index: number) => (
                  <li key={index}>{point.trim()}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
