import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import folderIcon from "../assets/images/FolderIcon.svg";
import { Link } from "react-router-dom";
import { routePath } from "config/routeMgt/routePath";
import { useState } from "react";
import { CreateFolder } from "../components/CreateFolder";
import { useGetFolders } from "../hooks/useGetFolders";
import { usePagination } from "hooks/usePagination";
import { ScaleLoader } from "react-spinners";
import { Button, Dropdown, Menu, Pagination, Popconfirm } from "antd";
import { useDelete } from "hooks/useDelete";
import { QUERY_KEY_FOR_FOLDERS } from "../hooks/useGetFolders";
import { EditFolder } from "../components/EditFolder";
import { useGetUserInfo } from "hooks/useGetUserInfo";

const deleteEndPointUrl = "delete-folder/";
export const Folders = () => {
  const [newFolder, setNewFolder] = useState(false);
  const [editFolder, setEditFolder] = useState(false);
  const [folderId, setFolderId] = useState<number>();
  const { pagination, onChange } = usePagination();
  const { data, isLoading } = useGetFolders({ pagination });
  const { userInfo, token } = useGetUserInfo();

  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_FOLDERS,
    deleteEndPointUrl,
  });
  const addFolder = () => {
    setNewFolder(true);
  };
  const handleFolder = (id: number) => {
    setFolderId(id);
    setEditFolder(true);
  };


  return (
    <DashboardLayout>
      <Navbar title="Folders" description="Create and manage folders" />
      <CreateFolder
        open={newFolder}
        handleClose={() => setNewFolder(false)}
      />
      <EditFolder
        id={folderId}
        open={editFolder}
        handleClose={() => setEditFolder(false)}
      />
      <div className="flex justify-end">
        <button
          className="button flex items-center gap-x-2"
          onClick={addFolder}
        >
          <i className="ri-checkbox-blank-circle-fill text-xs"></i>
          <span>Create Folder</span>
        </button>
      </div>

      <div className="flex justify-center mt-8">
        {isLoading ? <ScaleLoader color="var(--app-color-primary)" /> : null}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {data?.data.map((item) => (
          
          <div
            key={item.id}
            className="flex items-center justify-between gap-x-3 bg-white border rounded-md p-3 hover:border-b hover:border-primary transition-all ease-in-out duration-300"
          >
            <div className="flex items-center gap-x-2">
              <Link to={routePath.folderDetails(item.id).path}>
                <img
                  src={folderIcon}
                  alt="folder logo"
                  className="md:h-11 h-5 hover:border border-gray-600 rounded-full"
                />
              </Link>
              <div className="flex flex-col">
                <Link
                  to={routePath.folderDetails(item.id).path}
                  className="text-primary text-sm font-medium capitalize hover:text-black"
                >
                  {item.name}
                </Link>
                {item?.owner && <p className="flex text-xs text-gray-500 items-center"><i className="ri-user-fill text-xs"></i> {userInfo?.recruiter.id == item.owner.id ? 'You' : item.owner.first_name + ' ' + item.owner.last_name}</p>}
              </div>
            </div>
            <div className="flex items-center">
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <Link to={routePath.folderDetails(item.id).path}>View</Link>
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => handleFolder(item.id as unknown as number)}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Popconfirm
                        okButtonProps={{
                          style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                        }}
                        title="Delete folder"
                        description="Are you sure to delete this folder?"
                        onConfirm={() => removeData(item.id as unknown as number)}
                        okText="Yes delete"
                        cancelText="Cancel"
                      >
                        Delete
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <i className="ri-more-2-fill text-lg font-semibold hover:text-black cursor-pointer"></i>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>

      <div className={isLoading ? "hidden" : "flex justify-end mt-3"}>
        <Pagination
          total={data?.total}
          onChange={onChange}
          current={pagination.current}
        />
      </div>
    </DashboardLayout>
  );
};
