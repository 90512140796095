

import { END_POINT } from "config/environment";
import { useState } from 'react';
import type { Profile } from 'features/cvChat/lib/db/schema';
import { cn } from 'features/cvChat/lib/utils';
import { Link, useParams } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from 'features/cvChat/components/ui/avatar';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'features/cvChat/components/ui/select';
import { Button } from 'features/cvChat/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Checkbox } from 'features/cvChat/components/ui/checkbox';
import { ProfileBulkActions } from 'features/cvChat/components/profile-bulk-action';
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { routePath } from "config/routeMgt/routePath";

import "../(chat)/assets/style.css";

export function ProfileList({ profiles, setProfiles }: { profiles: Profile[], setProfiles: React.Dispatch<React.SetStateAction<Profile[]>> }) {
  const params = useParams();
  const selectedId = params.profileId;
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProfiles, setSelectedProfiles] = useState<Set<string>>(new Set());

  const totalProfiles = profiles.length;
  const totalPages = Math.ceil(totalProfiles / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentProfiles = profiles.slice(startIndex, endIndex);

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const toggleProfile = (profileId: string) => {
    setSelectedProfiles((prev) => {
      const next = new Set(prev);
      if (next.has(profileId)) {
        next.delete(profileId);
      } else {
        next.add(profileId);
      }
      return next;
    });
  };

  const toggleAll = () => {
    if (selectedProfiles.size === currentProfiles.length) {
      setSelectedProfiles(new Set());
    } else {
      setSelectedProfiles(new Set(currentProfiles.map((p) => p.id)));
    }
  };
  const { token } = useGetUserInfo();
  const handleDeleteSelected = async () => {

    try {
      await Promise.all(
        Array.from(selectedProfiles).map((id) =>
          fetch(`${END_POINT.BASE_URL}/v1/profiles/${id}`, {
            method: 'DELETE', headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          })
        )
      );
      const remainingProfiles = profiles.filter(profile => !selectedProfiles.has(profile.id));
      setSelectedProfiles(new Set());
      setProfiles(remainingProfiles);

      // Replace router.refresh() with any desired behavior in React (like state update)
    } catch (error) {
      console.error('Error deleting profiles:', error);
    }
  };


  const handleShareSelected = () => {
    if (selectedProfiles.size === 0) return;

    // Generate the list of profile URLs
    const profileUrls = Array.from(selectedProfiles).map((profileId) => {
      const profile = profiles.find((p) => p.id === profileId);
      return profile ? routePath.chatProfilesById(params.id, profileId).path : '';
    });


    const mailtoLink = `mailto:?subject=Check out these profiles&body=${encodeURIComponent(
      profileUrls.join('\n')
    )}`;


    const shareViaEmail = () => {
      window.location.href = mailtoLink;
    };


    const copyToClipboard = () => {
      navigator.clipboard.writeText(profileUrls.join('\n')).then(() => {
        alert('Links copied to clipboard!');
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    };


    const action = window.confirm(
      'Do you want to send the profiles via email or copy the link to your clipboard? Press "OK" to email or "Cancel" to copy the link.'
    );

    if (action) {

      shareViaEmail();
    } else {

      copyToClipboard();
    }
  };


  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={currentProfiles.length > 0 && selectedProfiles.size === currentProfiles.length}
              onCheckedChange={toggleAll}
            />
            <h2 className="text-lg font-semibold">
              Profiles ({totalProfiles})
            </h2>
          </div>
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => {
              setPageSize(Number(value));
              setCurrentPage(1);
            }}
          >
            <SelectTrigger className="w-fit text-sm">
              <SelectValue placeholder="Select page size" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="20">Show 20</SelectItem>
              <SelectItem value="50">Show 50</SelectItem>
              <SelectItem value="100">Show 100</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <ProfileBulkActions
          selectedCount={selectedProfiles.size}
          onDeleteSelected={handleDeleteSelected}
          onShareSelected={handleShareSelected}
        />
      </div>

      {/* Profile list */}
      <div className="flex-1 overflow-y-auto p-2 space-y-2">
        {currentProfiles.map((profile) => (
          <div
            key={profile.id}
            className={cn(
              'flex items-center gap-3 px-2 py-2 rounded-lg hover:bg-accent',
              selectedId === profile.id && 'bg-accent',
            )}
          >
            <Checkbox
              checked={selectedProfiles.has(profile.id)}
              onCheckedChange={() => toggleProfile(profile.id)}
              onClick={(e) => e.stopPropagation()}
            />
            <Link to={routePath.chatProfilesById(params.id, profile.id).path} >

              <Avatar className="size-10">
                {profile.linkedinImage ? (
                  <AvatarImage src={profile.linkedinImage} alt={profile.name} />
                ) : (
                  <AvatarFallback className="bg-primary/10">
                    {getInitials(profile.name)}
                  </AvatarFallback>
                )}
              </Avatar>
              <div>
                <h3 className="font-medium">{profile.name}</h3>
                <p className="text-sm text-muted-foreground">
                  {profile.bestSuitedFor}
                </p>
              </div>

            </Link>

          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div className="p-4 border-t flex items-center justify-between">
        <span className="text-sm text-muted-foreground">
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="size-4" />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
          >
            <ChevronRight className="size-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
