import { Tabs } from "antd";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Profile } from "./components/Profile";
import { Navbar } from "components/layout/components/Navbar";
import { Password } from "./components/Password";
import { Billings } from "./components/Billings";
import { TalentProfile } from "./components/talentProfile/TalentProfile";
import { PersonalityTest } from "./components/talentProfile/PersonalityTest";
import { Documents } from "./components/talentProfile/Documents";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { RecruiterDocuments } from "./components/RecruiterDocuments";
import { useLocation, useNavigate } from "react-router-dom";

export const Settings = () => {
  const { token, userInfo } = useGetUserInfo();
  const location = useLocation();
  const navigate = useNavigate();

  // Map each tab key to a route
  const getKeyFromPath = (pathname: string) => {
    switch (pathname) {
      case '/settings/profile': return '1';
      case '/settings/documents': return '3';
      case '/settings/personality-test': return '5';
      case '/settings/password': return '6';
      case '/settings/billings': return '7';
      default: return '1';
    }
  };

  const getPathFromKey = (key: string) => {
    switch (key) {
      case '1': return '/settings/profile';
      case '3': return '/settings/documents';
      case '5': return '/settings/personality-test';
      case '6': return '/settings/password';
      case '7': return '/settings/billings';
      default: return '/settings/profile';
    }
  };

  // Get the current tab key from the URL
  const activeTabKey = getKeyFromPath(location.pathname);

  // Handle tab change and navigate to the appropriate route
  const handleTabChange = (key: string) => {
    navigate(getPathFromKey(key));
  };

  return (
    <DashboardLayout>
      <Navbar title="Settings" description="" />
      <Tabs 
        activeKey={activeTabKey} 
        onChange={handleTabChange} 
        size="large"
      >
        <Tabs.TabPane tab="Profile" key="1">
          {userInfo.type === "talent" ? <TalentProfile /> : <Profile />}
        </Tabs.TabPane>

        {/* {userInfo.type === "talent" && (
          <Tabs.TabPane tab="Documents" key="3">
            <Documents />
          </Tabs.TabPane>
        )} */}

        {/* userInfo.type !== "talent" && (
          <Tabs.TabPane tab="Documents" key="4">
            <RecruiterDocuments />
          </Tabs.TabPane>
        ) */}

        {/* {userInfo.type === "talent" && (
          <Tabs.TabPane tab="Personality Test" key="5">
            <PersonalityTest />
          </Tabs.TabPane>
        )} */}

        <Tabs.TabPane tab="Password" key="6">
          <Password />
        </Tabs.TabPane>

        {(userInfo?.recruiter?.parent_id == null && userInfo?.current_team === undefined) && (
          <Tabs.TabPane tab="Plans and Billings" key="7">
            <Billings />
          </Tabs.TabPane>
        )}
      </Tabs>
    </DashboardLayout>
  );
};
