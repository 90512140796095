import type { Attachment } from 'ai';
import { FileIcon, LoaderIcon, TrashIcon } from './icons';

export const PreviewAttachment = ({
  attachment,
  isUploading = false,
  onRemove,
}: {
  attachment: Attachment;
  isUploading?: boolean;
  onRemove?: () => void;
}) => {
  const { name, url, contentType } = attachment;

  return (
    <div className="flex flex-col gap-2">
      <div className="w-20 h-16 aspect-video bg-muted rounded-md relative flex flex-col items-center justify-center group">
        {contentType ? (
          contentType.startsWith('image') ? (
            
            <img
              key={url}
              src={url}
              alt={name ?? 'An image attachment'}
              className="rounded-md size-full object-cover"
            />
          ) : contentType === 'application/pdf' ? (
            <div className="flex items-center justify-center text-muted-foreground">
              <FileIcon />
            </div>
          ) : (
            <div className="flex items-center justify-center text-muted-foreground">
              <FileIcon />
            </div>
          )
        ) : (
          <div className="flex items-center justify-center text-muted-foreground">
            <FileIcon />
          </div>
        )}

        {isUploading && (
          <div className="animate-spin absolute text-zinc-500">
            <LoaderIcon />
          </div>
        )}

        {!isUploading && onRemove && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onRemove();
            }}
            // className="cursor-pointer text-destructive focus:bg-destructive/15 focus:text-destructive dark:text-red-500"
            className="absolute -top-2 -right-2 p-2 rounded-full focus:text-destructive bg-background border shadow-sm opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <TrashIcon />
          </button>
        )}
      </div>
      <div className="text-xs text-zinc-500 max-w-16 truncate">{name}</div>
    </div>
  );
};
