import { Chat } from 'features/cvChat/components/chat';
import { DEFAULT_MODEL_NAME, models } from 'features/cvChat/lib/ai/models';
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { generateUUID } from 'features/cvChat/lib/utils';
import { DataStreamHandler } from 'features/cvChat/components/data-stream-handler';
import { SidebarProvider } from '../components/ui/sidebar';
import { AppSidebar } from '../components/app-sidebar';
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import { Navbar } from "components/layout/components/Navbar";
import "./assets/style.css";

export default function Page() {
  const id = generateUUID();

  
  const modelIdFromCookie = "gemini-1.5-pro";

  const selectedModelId =
    models.find((model) => model.id === modelIdFromCookie)?.id ||
    DEFAULT_MODEL_NAME;
  const isCollapsed = 'true';
  const { userInfo } = useGetUserInfo();
  const session = userInfo;

  return (
    <>

      <script
        src="https://cdn.jsdelivr.net/pyodide/v0.23.4/full/pyodide.js"
        async
      ></script>
      <DashboardLayout>
        <Navbar title="CV Chats" description="" />
        <SidebarProvider defaultOpen={!isCollapsed}>
          <AppSidebar user={session} />

          <Chat
            key={id}
            id={id}
            initialMessages={[]}
            selectedModelId={selectedModelId}
            selectedVisibilityType="private"
            isReadonly={false}
            isChatPage={true}
          />
          <DataStreamHandler id={id} />

        </SidebarProvider>
      </DashboardLayout>
    </>
  );
}
