


import type { Attachment, Message } from 'ai';
import { useChat } from 'ai/react';
import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { END_POINT } from "config/environment";


import { ChatHeader } from 'features/cvChat/components/chat-header';

import type { Vote } from 'features/cvChat/lib/db/schema';
import { fetcher } from 'features/cvChat/lib/utils';

import { Block } from './block';
import { MultimodalInput } from './multimodal-input';
import { Messages } from './messages';
import type { VisibilityType } from './visibility-selector';
import { useBlockSelector } from 'features/cvChat/hooks/use-block';
import { useGetUserInfo } from "hooks/useGetUserInfo";

export function Chat({
  id,
  initialMessages,
  selectedModelId,
  selectedVisibilityType,
  isReadonly,
  isChatPage,
}: {
  id: string;
  initialMessages: Array<Message>;
  selectedModelId: string;
  selectedVisibilityType: VisibilityType;
  isReadonly: boolean;
  isChatPage: boolean;
}) {
  const { mutate } = useSWRConfig();
  const { token } = useGetUserInfo();

  const {
    messages,
    setMessages,
    handleSubmit,
    input,
    setInput,
    append,
    isLoading,
    stop,
    reload,
  } = useChat({
    api: `${END_POINT.BASE_URL}/v1/chat`,
    async onResponse(response) {
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      let buffer = '';

      while (true) {
        const { done, value } = await reader!.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; 

        for (const line of lines) {
          if (line.trim() === '') continue;
          try {
            const { event, data } = JSON.parse(line);

            
            switch (event) {
              case 'text-delta':
                setMessages(prev => {
                  const lastMessage = prev[prev.length - 1];
                  if (lastMessage?.role === 'assistant') {
                    return prev.slice(0, -1).concat({
                      ...lastMessage,
                      content: lastMessage.content + data.content
                    });
                  }
                  return prev.concat({
                    id: Date.now().toString(),
                    role: 'assistant',
                    content: data.content
                  });
                });
                break;
              case 'document-created':
                
                break;
              
            }
          } catch (error) {
            console.error('Error parsing JSON line:', line, error);
          }
        }
      }
    },
    id,
    body: {
      id,
      modelId: selectedModelId,
    },
    initialMessages,
    experimental_throttle: 100,

    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    onFinish: () => {
      // console.log(initialMessages);
      mutate(`${END_POINT.BASE_URL}/v1/chat-history`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });



  const { data: votes } = useSWR<Array<Vote>>(
    token && `${END_POINT.BASE_URL}/v1/cv-vote?chatId=${id}`,
    (url: string) => fetcher(url, token),
  );

  const [attachments, setAttachments] = useState<Array<Attachment>>([]);
  const isBlockVisible = useBlockSelector((state) => state.isVisible);

  return (
    <>

      <div className="flex flex-col min-w-0 h-dvh bg-background" style={{ width: '100%' }}>
        <ChatHeader
          chatId={id}
          selectedModelId={selectedModelId}
          selectedVisibilityType={selectedVisibilityType}
          isReadonly={isReadonly}
          isChatPage={isChatPage}
        />


        <Messages
          chatId={id}
          isLoading={isLoading}
          votes={votes}
          messages={messages}
          setMessages={setMessages}
          reload={reload}
          isReadonly={isReadonly}
          isBlockVisible={isBlockVisible}
        />

        <form className="flex mx-auto px-4 bg-background pb-4 md:pb-6 gap-2 w-full md:max-w-3xl">
          {!isReadonly && (
            <MultimodalInput
              chatId={id}
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              stop={stop}
              attachments={attachments}
              setAttachments={setAttachments}
              messages={messages}
              setMessages={setMessages}
              append={append}
            />
          )}
        </form>
      </div>

      <Block
        chatId={id}
        input={input}
        setInput={setInput}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        stop={stop}
        attachments={attachments}
        setAttachments={setAttachments}
        append={append}
        messages={messages}
        setMessages={setMessages}
        reload={reload}
        votes={votes}
        isReadonly={isReadonly}
      />
    </>
  );
}
